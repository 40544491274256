import React from "react";
import { Container, Paper } from "@material-ui/core";
import PrayerRequestsSimple from "../components/PrayerRequestsSimple";

export default function PrayerRequestsPage() {
  return (
    <Container maxWidth="md">
      <Paper>
        <PrayerRequestsSimple />
      </Paper>
    </Container>
  );
}
